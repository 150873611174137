import React, {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from "react";
import Moment from "moment";
import ReactDatePicker from "react-datepicker";
import CustomSelect from "../../../CustomSelect";
import {DateRange, extendMoment} from "moment-range";
import "./styles.scss";
import {getAvailable} from "../../../../http/schedule";
import LoaderComponent from "../../../Loader";
import {doctorScheduleEvent} from "../../../DoctorListSchedule";
import {ProductCategory} from "../../../../model/ProductCategory";
import MessageConst from "../../../../const/Message.const";
import {useSelector} from "react-redux";
import {StateType} from "../../../../redux/types";
import Verification from "../Verification";
import globe_icon from "../../../../static/images/globe-icon.png";


// @ts-ignore
const moment = extendMoment(Moment);

export interface IConsultationScheduleCardRefObject {
	getTimeSlotId: () => dayTimeSlotT & {selectedDate: Date};
	validate: () => boolean;
}

interface IConsultationScheduleCardProps {
	selectedProductsCategories: string[];
	handleNextStep: () => void;	
	handleNextClick:()=> void;
	doctorConsultDate: any;
}

export type dayTimeSlotT = {value: number; label: string};

type fullOptionsT = {[d: string]: dayTimeSlotT[]};

interface IConsultationScheduleState {
	selectedDate: Date;
	datesRange: {start: Date; end: Date};
	dayTimeSlots: dayTimeSlotT[];
	selectedTime: dayTimeSlotT | string;
	isLoading: boolean;
	fullOptions: fullOptionsT;
	validationMessage: string;
}

const ConsultationSchedule = forwardRef(
	(
		props: IConsultationScheduleCardProps,
		ref: Ref<IConsultationScheduleCardRefObject>,
	) => {
		const [
			{
				datesRange,
				selectedDate,
				selectedTime,
				dayTimeSlots,
				isLoading,
				fullOptions,
				validationMessage
			},
			setState
		] = useState<IConsultationScheduleState>({
			selectedDate: props.doctorConsultDate?props.doctorConsultDate.selDate:new Date(),
			datesRange: {start: ("" as unknown) as Date, end: ("" as unknown) as Date},
			dayTimeSlots: [],
			selectedTime: props.doctorConsultDate?props.doctorConsultDate.selTime:'',
			isLoading: false,
			fullOptions: {},
			validationMessage: ""
		});
		const fieldRef = useRef<HTMLInputElement>(null);

		
		const [consultation] = useState<null | {
			consultationDateTime: string;
			slotId: number;
			selDate: Date;
			selTime: dayTimeSlotT
		}>(null);
		const { profile} = useSelector(({auth}: StateType) => ({
			// phone: auth.profile?.phone,
			profile: auth.profile
		}));

		const handleUploadDocument = () =>{			
			if(!profile?.idDocument){				
				setValidationMessage(MessageConst.validation.idError);
			}else{
				props.handleNextStep();
			}			
		}
		
		const setValidationMessage = (message: string) =>
			setState((s) => ({...s, validationMessage: message ?? ""}));

		const validate = () => {
			if (validationMessage) {
				setValidationMessage("");
			}
			if (!selectedTime) {
				setValidationMessage(MessageConst.validation.doctorConsult.time);

				return false;
			}
			setValidationMessage("");
			return true;
		};

		const getTimeSlotId = (): dayTimeSlotT & {selectedDate: Date} => {
			return {...(selectedTime as dayTimeSlotT), selectedDate};
		};
		useImperativeHandle(ref, () => ({getTimeSlotId, validate}));

		const toggleLoader = (val: boolean) => setState((s) => ({...s, isLoading: val}));

		const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) => obj[key];

		const[estTime, setEstTime] = useState<string>();
		const[show,setShow]=useState(props.doctorConsultDate?true:false) ;

		
		useEffect(() => {
			setTimeForEst();
		},[])

		const setTimeForEst = () =>{
			var d = new Date();
			var myTimezone = "America/Toronto";
			var myDatetimeFormat= "hh:mm A";
			var myDatetimeString = moment(d).tz(myTimezone).format(myDatetimeFormat);
			setEstTime(myDatetimeString);
		}

		useEffect(() => {
			const start = moment();
			const end = moment().add("7", "d");
			const range: DateRange = moment().range(new Date(), end);
			const dates = Array.from(range.by("day")).map((m: Moment.Moment) => m.format("DD/MM/YYYY"));
			const sortTime = (a: string, b: string) => {
				const [startHours, startMinutes] = a.split(":");
				const [endHours, endMinutes] = b.split(":");
				if (+startHours > +endHours) {
					return 1;
				} else if (+startHours < +endHours) {
					return -1;
				} else if (+startMinutes > +endMinutes) {
					return 1;
				} else if (+startMinutes < +endMinutes) {
					return -1;
				} else {
					return 0;
				}
			};
			toggleLoader(true);
			getAvailable({
				dates,
				problemCategory: props.selectedProductsCategories[0] as ProductCategory
			})
				.then(({data}: {data: doctorScheduleEvent[]}) => {
					const initFullOptions = data.reduce((obj, {date, schedule}) => {
						const options = Object.keys(schedule)
							.sort(sortTime)
							.reduce((arr, key) => {
								const time = key.split(":");
								const deadlineTime = moment().tz("America/Toronto").add(15, "minutes");
								const isToday = moment(date).isSame(new Date(), "day");

								if (
									(isToday &&
										(deadlineTime.hour() < +time[0] ||
											(deadlineTime.hour() === +time[0] && deadlineTime.minute() < +time[1]))) ||
									!isToday
								) {
									const formattedTime = moment(
										`${moment().format("YYYY-MM-DD")} ${time.join(":")}`
									).format("h:mm a");
									return [
										...arr,
										{
											value: schedule[key][0].id,
											label: formattedTime
										}
									];
								}

								return arr;
							}, [] as dayTimeSlotT[]);

						return {...obj, [date]: options};
					}, {} as {[d: string]: dayTimeSlotT[]});

					console.log(
						getKeyValue<string, fullOptionsT>(moment(selectedDate).format("YYYY-MM-DD").replace(/-/g, ","))(
							initFullOptions
						)
					);

					setState((s) => ({
						...s,
						fullOptions: initFullOptions,
						dayTimeSlots: getKeyValue<string, fullOptionsT>(
							moment(s.selectedDate).format("YYYY-M-DD").replace(/-/g, ",")
						)(initFullOptions),
						datesRange: {start: start.toDate(), end: moment(end).subtract(1, "day").toDate()}
					}));
					toggleLoader(false);
				})
				.catch(() => toggleLoader(false));
		}, []);

		const handleClickDateItem = (selected: Date) => {
			setState((s) => ({
				...s,
				selectedDate: selected,
				selectedTime: ""
			}));
		};
	
		useEffect(() => {
			setState((s) => ({
				...s,
				dayTimeSlots: getKeyValue<string, fullOptionsT>(
					moment(s.selectedDate).format("YYYY-M-DD").replace(/-/g, ",")
				)(fullOptions)
			}));
		}, [selectedDate]);

		useEffect(() => {
			if (validationMessage) {
				setValidationMessage("");
			}
		}, [selectedTime]);
		
		const scrollView = () => {			
			
			if ( fieldRef.current) {				
				fieldRef.current.scrollIntoView({behavior:'smooth', block: "start", inline: 'nearest'});
			  }
		}
		
		const executeScroll = async()=>{
			if( selectedTime ){
				await setShow(true);
				scrollView()

			}	
			else{
				return false
			}
			 		
		}
	

		return isLoading ? (
			<LoaderComponent />
		) : (
			<div className="ConsultationSchedule" id="cschdule">
				
				<div className="section-5">
					
					<div className="container-7 w-container">
					<div className="w-row">
						<div className="w-col w-col-3"></div>
						<div className="column-56 w-col w-col-6">
						<h1 className="heading-6">Doctor consultation</h1>
						<p className="paragraph-6">To receive your treatment you must first consult with a licensed Canadian doctor. Please select the date and time that is convenient for you.</p>
						</div>
						<div className="w-col w-col-3"></div>
					</div>
					</div>
					<div className="container-16 w-container">
					<div className="w-row">
						<div className="w-col w-col-3"></div>
						<div className="column-55 w-col w-col-6 date-pick">

						
						
					<ReactDatePicker
						inline
						// fixedHeight
						calendarClassName="ConsultationSchedule_DateRanges"
						selected={selectedDate}
						minDate={datesRange.start}
						maxDate={datesRange.end}
						onChange={handleClickDateItem}
						useWeekdaysShort={true}
						showDisabledMonthNavigation={true}
					/>

							
						
						
						<div className="div-block-43"><img src={globe_icon} alt="globe" className="image-20" />
							<div>Eastern Time - US and Canada ({estTime})</div>
						</div>
						</div>
						<div className="w-col w-col-3"></div>
					</div>
					</div>
					<div className="container-6 w-container">
					<div className="w-row">
						<div className="w-col w-col-3"></div>
						<div className="column-36 w-col w-col-6">
						<div className="form-block w-form">
							<form id="email-form" name="email-form" data-name="Email Form" className="form-2"><label className="field-label-3">Select the time</label>
							
							<CustomSelect
							options={dayTimeSlots}
							value={selectedTime}
							onChange={(selected: string) => setState((s) => ({...s, selectedTime: selected}))}
							/>		
							{/* validation column */}
							<div className={validationMessage ? "w-form-fail-show" : "w-form-fail"}>
								<div>{validationMessage}</div>
							</div>
							<span 
							  onClick={()=>{props.handleNextClick();executeScroll()}}
							   className="button-19 w-button scroll">
								   <strong>Book the time</strong>
								   </span>							
							</form>							
						</div>
						</div>
						<div className="w-col w-col-3"></div>
					</div>
					</div>
				</div>	
				<div className="clearBoth"></div>						
				{show &&(	
					<div id="Appointment-Booked" className="Appointment-booked appointment" ref={fieldRef}>			  
				     <>
					 <Verification
								doctorConsultDate={consultation?.consultationDateTime} />
								<div className="container-18 w-container">
									<div className="w-row">
										<div className="w-col w-col-3"></div>
										{/* validation column */}
										<div className="column-50 w-col w-col-6">
											<div className={validationMessage && !profile?.idDocument? "w-form-fail-show" : "w-form-fail"}>
												<div>{validationMessage}</div>
											</div>
											<span onClick={handleUploadDocument} className="button-19 w-button"><strong>Save and Continue</strong></span>
										</div>
										<div className="w-col w-col-3"></div>
									</div>
								</div>
								</>
								</div>	
						 )}  
									
			</div>
			
		);
	}
);

ConsultationSchedule.displayName = "ConsultationSchedule";

export default ConsultationSchedule;
